<template>
  <div class="find-pw">
    <div class="wrapper">
      <div class="title font-md">
        <span>비밀번호 찾기</span>
      </div>
      <div class="desc font-sm">가입하셨던 이메일로 임시 비밀번호를 보내드릴게요.</div>
      <div class="form">
        <input type="email" :id="`${component.name}Email`" class="form-control border-focus-point" v-model="state.form.email" autocomplete="off" placeholder="이메일 주소" @keyup.enter="submit()"/>
      </div>
    </div>
    <div class="action">
      <button class="btn btn-point btn-block font-sm" @click="submit()">비밀번호 찾기</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Phone from "@/components/Phone";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalFindPw";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Phone},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});
    });

    const state = reactive({
      form: {
        name: "",
        mobile: "",
        email: "",
      }
    });

    const submit = () => {
      const args = {email: state.form.email};

      if (!args.email) {
        document.getElementById(`${component.name}Email`).focus();
        return store.commit("setSwingMessage", "이메일 주소를 입력해주세요.");
      } else if (!lib.isValidEmail(args.email)) {
        document.getElementById(`${component.name}Email`).focus();
        return store.commit("setSwingMessage", "이메일 주소를 올바르게 입력해주세요.");
      }

      http.post("/api/member/login/find/pw", args).then(({data}) => {
        store.commit("closeModal", {name: component.name});
        store.commit("setSwingMessage", data.message);
      }).catch(httpError());
    };

    return {component, state, submit};
  },
});
</script>

<style lang="scss" scoped>
.find-pw {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    .form {
      input {
        display: block;
        margin: $px13 0;
        height: $formHeightLg;
        font-size: $px14;
        border-color: #ddd;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .action {
    .btn {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }
  }
}
</style>